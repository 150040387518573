<template>
  <v-container grid-list-xs>
    <strong class="primary--text">EXCELLENCE ADMIN</strong>
  </v-container>
  <!-- <v-img
    alt="App Logo"
    class="shrink mr-2"
    contain
    transition="scale-transition"
    :width="width"
  /> -->
</template>

<script>
export default {
  props: {
    width: {
      default: 120,
      type: Number
    }
  }
}
</script>

<style lang="scss" scoped></style>
