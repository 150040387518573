import firebase from 'firebase/app'
import 'firebase/messaging'

export function initializeFirebase() {
  if (firebase.messaging.isSupported()) {
    const firebaseConfig = {
      apiKey: 'AIzaSyCkiFSi7qF6M7OfIIiHJm4A4b1_9RqARKE',
      authDomain: 'weighty-time-317606.firebaseapp.com',
      databaseURL: 'https://weighty-time-317606.firebaseio.com',
      projectId: 'weighty-time-317606',
      storageBucket: 'weighty-time-317606.appspot.com',
      messagingSenderId: '38591913783',
      appId: '1:38591913783:web:62acdb7e20f0cf68f6c4b2',
      measurementId: 'G-2QE5Y489CK'
    }

    firebase.initializeApp(firebaseConfig)

    const messaging = firebase.messaging()

    Notification.requestPermission()
      .then(() => {
        return messaging.getToken()
      })
      .then(token => {
        if (
          localStorage.getItem(`${process.env.VUE_APP_NAME}-4-admin-token`) &&
          localStorage.getItem(`${process.env.VUE_APP_NAME}-4-push-token`) !==
            token
        ) {
          localStorage.setItem(
            `${process.env.VUE_APP_NAME}-4-push-token`,
            token
          )
          console.log(token)
        }
      })
      .catch(error => {
        console.log(
          'push_notifications:initializeFirebase:requestPermission:Error:',
          error
        )
      })
  }
}
