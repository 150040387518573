<template>
  <div class="mx-2 fixed-width">
    <v-select
      class="caption"
      rounded
      filled
      flat
      color="primary"
      item-color="primary"
      background-color="primaryVarient"
      v-model="set_language"
      :items="languages"
      item-text="title"
      item-value="language"
      menu-props="auto"
      hide-details
      single-line
      dense
      v-on:change="changeLocale"
    ></v-select>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n'
import { setLanguage } from '@/app/base-module/usecase/change_language'

export default {
  data: () => {
    return {
      languages: [
        {
          language: 'en',
          title: 'English'
        },
        {
          language: 'ar',
          title: 'عربى'
        }
      ],
      set_language: i18n.locale
    }
  },
  methods: {
    changeLocale(locale) {
      const currentLocale = setLanguage(locale)
      if (currentLocale === 'ar') {
        this.$vuetify.rtl = true
      } else {
        this.$vuetify.rtl = false
      }
    }
  }
}
</script>

<style lang="css" scoped>
.fixed-width {
  width: 8rem !important;
}
</style>
