import { removeFromLocalStorage } from '../../base-module/util/localStorageHandler'
import { onLogout, apolloClient } from '@/plugins/vue-apollo.js'

const doSignOut = () => {
  onLogout(apolloClient)
  removeFromLocalStorage('User')
  removeFromLocalStorage('Role')
  removeFromLocalStorage('Permission')
  location.reload()
}

export { doSignOut }
