<template>
  <v-app>
    <AppNavingationBars />
    <v-main class="bg">
      <transition name="fade">
        <router-view />
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import AppNavingationBars from '@/app/base-module/component/AppNavingationBars'
import { setLanguage } from '@/app/base-module/usecase/change_language'
import { initializeFirebase } from '@/app/base-module/usecase/push_notifications'

export default {
  components: {
    AppNavingationBars
  },
  created() {
    initializeFirebase()
  },
  watch: {
    '$route.params.lang': {
      handler: function(lang) {
        if (lang != null) {
          setLanguage(lang, false)
          if (lang == 'ar') this.$vuetify.rtl = true
          else this.$vuetify.rtl = false
        }
      }
      // deep: true,
      // immediate: true
    }
  }
}
</script>

<style>
html {
  scroll-behavior: smooth;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #7cabdd;
  border-radius: 35px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7cabdd;
  border-radius: 35px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4086d2;
}

.v-label {
  font-size: 14px !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0;
}
.v-text-field__details {
  min-height: 0px !important;
}
</style>
