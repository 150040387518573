import { readFromLocalStorage } from '../../base-module/util/localStorageHandler'

const permissionCheck = permission => {
  try {
    const role = readFromLocalStorage('Role');
    
    if (role === 'ADMIN') {
      return true
    } else if (role === 'EMPLOYEE') {
      const permissionsList = JSON.parse(readFromLocalStorage('Permission'));
      return permissionsList.includes(permission)
    }
  } catch (_) {}

  return false;
}

export { permissionCheck }
