<template>
  <v-icon v-if="isAuthenticated()" @click="logout" class="me-4 error--text">mdi-logout</v-icon>
</template>

<script>
import { isAdmin } from '../usecase/authCheck'
import { doSignOut } from '@/app/user-module/usecase/signOut'
export default {
  methods: {
    isAuthenticated() {
      return isAdmin()
    },
    logout() {
      doSignOut()
      this.$router.replace({ path: 'signin' })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>