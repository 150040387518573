import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '../plugins/i18n.js'
import { isAdmin } from '@/app/user-module/usecase/authCheck'
import { readFromLocalStorage } from '@/app/base-module/util/localStorageHandler'
import { permissionCheck } from "../app/user-module/usecase/PermissionCheck";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: `/${i18n.locale}/Statistics`
  },
  {
    path: '/:lang',
    component: {
      render: h => h('router-view')
    },
    children: [
      {
        path: '',
        redirect: `statistics`
      },
      {
        path: 'signin',
        name: 'Signin',
        meta: { redirectAuthUser: true },
        component: () =>
          import(
            /* webpackChunkName: 'signin' */ '../app/user-module/page/Signin.vue'
          )
      },
      // {
      //   path: 'dashboard',
      //   name: 'Dashboard',
      //   meta: { requiresAuth: true },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: 'Dashboard' */ '../app/dashboard/pages/Dashboard.vue'
      //     )
      // },
      // {
      //   path: 'applications',
      //   name: 'Applications',
      //   meta: { requiresAuth: true },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: 'Application' */ '../app/application/pages/Application.vue'
      //     )
      // },
      // {
      //   path: 'bill-type',
      //   name: 'BillType',
      //   meta: { requiresAuth: true },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: 'Application' */ '../app/bill-types/pages/BillType.vue'
      //     )
      // },
      // {
      //   path: 'bill',
      //   name: 'Bill',
      //   meta: { requiresAuth: true },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: 'Application' */ '../app/bill/pages/Bills.vue'
      //     )
      // },
      {
        path: 'statistics',
        name: 'Statistics',
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: 'Statistics' */ '../app/statistics/pages/Statistics.vue'
          )
      },
      {
        path: 'service',
        name: 'Service',
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: 'Statistics' */ '../app/service/pages/Service.vue'
          )
      },
      {
        path: 'client',
        name: 'Client',
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: 'Statistics' */ '../app/client/pages/Client.vue'
          )
      },
      {
        path: 'whatsapp',
        name: 'WhatsApp',
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: 'Statistics' */ '../app/whatsapp/pages/WhatsApp.vue'
          )
      },
      {
        path: 'socialmedia',
        name: 'SocialMedia',
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: 'Statistics' */ '../app/socialmedia/pages/SocialMedia.vue'
          )
      },
      {
        path: 'service-type',
        name: 'ServiceType',
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: 'Statistics' */ '../app/service-type/pages/ServiceType.vue'
          )
      },
      {
        path: 'updatePassword',
        name: 'UpdatePassword',
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: 'Statistics' */ '../app/updatePassword/pages/UpdatePassword.vue'
          )
      },

    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const auth = isAdmin()
  let language = to.params.lang
  if (!language) {
    language = 'en'
  }
  i18n.locale = language

  // redirect unauth users to Signin  page
  if (to.meta.requiresAuth && !auth) {
    next({ name: 'Signin', params: { lang: i18n.locale } })
  } else if (to.meta.redirectAuthUser && auth) {
    // redirect Dashboard page for autheticated users
    if (readFromLocalStorage('Role') == 'ADMIN') {
      next({ name: 'Statistics', params: { lang: i18n.locale } })
    } else {
      next({ name: 'map-view', params: { lang: i18n.locale } })
    }
  } else if (to.meta.permission != null && permissionCheck(to.meta.permission) !== true) {
    if (readFromLocalStorage('Role') == 'ADMIN') {
      next({ name: 'Statistics', params: { lang: i18n.locale } })
    } else {
      next({ name: 'map-view', params: { lang: i18n.locale } })
    }
  } else {
    next()
  }
})
export default router
