import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  rtl: false,
  theme: {
    themes: {
      light: {
        primary: '#007ebd',
        primaryVarient: '#F7EBEE',
        secondary: '#3A3A3A',
        secondaryVarient: '#F9F5D6',
        bg: '#F5F5F5',
        error: 'FF6A85'
      }
    }
  }
})
