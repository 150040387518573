<template>
  <nav>
    <!-- app bar  -->
    <v-app-bar app color="white" elevation="0" :clipped-left="!$store.getters.rtl" :clipped-right="$store.getters.rtl">
      <v-container fluid>
        <div class="d-flex align-center">
          <v-icon v-if="isAuthenticated()" @click="show_drawer = !show_drawer" class="me-4 primary--text">{{ show_drawer ?
            'mdi-arrow-left' : 'mdi-menu' }}</v-icon>
          <AppLogo class="mt-1 hidden-sm-and-down" />
          <v-spacer></v-spacer>
          <!-- <AppLanguageSwitcher /> -->
          <SignOutButton />
        </div>
      </v-container>
    </v-app-bar>

    <!-- drawer  -->

    <v-navigation-drawer v-if="isAuthenticated()" :right="$store.getters.rtl" app v-model="show_drawer" elevation="0"
      enable-resize-watcher clipped width="220">
      <v-divider></v-divider>

      <v-list dense class="mt-6" shaped>
        <div v-for="item in items" :key="$store.getters.rtl ? item.titleAr : item.titleEn">
          <v-list-item dense link class="my-1" :to="`/${$i18n.locale}/${item.to}`" active-class="white--text primary">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content class="mx-n4">
              <v-list-item-title>{{
                $store.getters.rtl ? item.titleAr : item.titleEn
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AppLogo from '@/app/base-module/component/AppLogo'
import SignOutButton from '@/app/user-module/component/SignOutButton'
import AppLanguageSwitcher from '@/app/base-module/component/AppLanguageSwitcher'
import { isAdmin } from '@/app/user-module/usecase/authCheck'
import { permissionCheck } from '@/app/user-module/usecase/PermissionCheck'

export default {
  methods: {
    ...mapActions(['showSideDrawer'])
  },
  components: {
    AppLogo,
    AppLanguageSwitcher,
    SignOutButton
  },
  methods: {
    permissionCheck,
    isAuthenticated() {
      return isAdmin()
    }
  },

  data() {
    return {
      show_drawer: true,
      items: [
        
        {
          titleEn: 'Statistics',
          titleAr: '',
          icon: 'mdi-chart-bar',
          to: 'statistics'
        },
        {
          titleEn: 'Service Type',
          titleAr: '',
          icon: 'mdi-webhook',
          to: 'service-type'
        },
        {
          titleEn: 'Service',
          titleAr: '',
          icon: 'mdi-bookmark-outline',
          to: 'service'
        },
        {
          titleEn: 'Client',
          titleAr: '',
          icon: 'mdi-account-outline',
          to: 'client'
        },
        {
          titleEn: 'Whats App',
          titleAr: '',
          icon: 'mdi-whatsapp',
          to: 'whatsapp'
        },
        {
          titleEn: 'Social Media',
          titleAr: '',
          icon: 'mdi-webhook',
          to: 'socialmedia'
        },
       
        {
          titleEn: 'Change Password',
          titleAr: '',
          icon: 'mdi-account',
          to: 'updatePassword'
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped></style>
