import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    rtl: false,
    show_drawer: false,
    drawer_action_name: '',
    show_app_drawer: false,
    current_order: {},
    dialog_name: '',
    open_or_close_dialog: false
  },
  getters: {
    rtl: state => state.rtl,
    show_drawer: state => state.show_drawer,
    is_drawer: state => state.drawer_action_name,
    show_app_drawer: state => state.show_app_drawer,
    current_order: state => state.current_order,
    dialog_name: state => state.dialog_name,
    open_or_close_dialog: state => state.open_or_close_dialog
  },
  mutations: {
    UPDATE_RTL: (state, value) => {
      state.rtl = value
    },
    SHOW_DRAWER: (state, actionName) => {
      state.show_drawer = !state.show_drawer
      state.drawer_action_name = actionName
    },
    SHOW_APP_DRAWER: state => {
      state.show_app_drawer = !state.show_app_drawer
    },
    UPDATE_CURRENT_ORDER: (state, value) => {
      state.current_order = {}
      state.current_order = value
    },
    UPDATE_DIALOG_NAME: (state, value) => {
      state.dialog_name = value
    },
    OPEN_CLOSE_MODAL: (state, value) => {
      state.open_or_close_dialog = value
    }
  },
  actions: {
    showSideDrawer({ commit }, actionName) {
      commit('SHOW_DRAWER', actionName)
    },
    showAppDrawer({ commit }) {
      commit('SHOW_APP_DRAWER')
    }
  }
})
