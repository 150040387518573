import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { createProvider } from './plugins/vue-apollo'
import * as VueGoogleMaps from 'vue2-google-maps'
import JsonExcel from 'vue-json-excel'
import firebase from 'firebase'

import Notifications from './plugins/notification'
import VueNativeNotification from './plugins/vue-native-notification'
import moment from "vue-moment";
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

Vue.use(VuejsDialog);

Vue.config.productionTip = false
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('DownloadExcel', JsonExcel)
Vue.use(moment);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyC6SGr6iVci_CTcgtY7UWvxksN8kx9dRn0'
    // libraries: "places", // necessary for places input
    // region: "uk,en"
  }
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  Notifications,
  VueNativeNotification,
  firebase,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')
