export const saveToLocalStorage = (key, value) => {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(`${process.env.VUE_APP_NAME}_4_${key}`, value)
  }
}

export const readFromLocalStorage = key => {
  if (typeof localStorage !== 'undefined') {
    return localStorage.getItem(`${process.env.VUE_APP_NAME}_4_${key}`)
  }
}

export const removeFromLocalStorage = key => {
  if (typeof localStorage !== 'undefined') {
    return localStorage.removeItem(`${process.env.VUE_APP_NAME}_4_${key}`)
  }
}
