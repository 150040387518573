import i18n from '@/plugins/i18n'
import router from '@/router'
import store from '@/store'

const setLanguage = (locale, forceReRoute) => {
  if (forceReRoute == null) forceReRoute = true
  i18n.locale = locale
  if (locale === 'ar') {
    setLanguageAsArabic(locale, forceReRoute)
  } else {
    setLanguageAsEnglish(locale, forceReRoute)
  }
  return locale
}

// set english
function setLanguageAsEnglish(locale, forceReRoute) {
  store.commit('UPDATE_RTL', false)
  if (forceReRoute)
    router.push({
      params: { lang: locale }
    })
}

// set arabic
function setLanguageAsArabic(locale, forceReRoute) {
  store.commit('UPDATE_RTL', true)
  if (forceReRoute)
    router.push({
      params: { lang: locale }
    })
}

export { setLanguage }
